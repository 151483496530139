.menuOptions {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  color: #272522;
}

.chevronDownIcon {
  width: 16px !important;
  height: 16px !important;
  fill: white !important;
}
.menuItems {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  padding: 6px 14px 6px 14px;
}

.menuItems:hover {
  background-color: #f0efee !important;
}
.menuItems:first-child {
  margin-top: 0px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.menuItems:last-child {
  padding-bottom: 6px;
}
