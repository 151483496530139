.text {
  color: #493ab1;
  fill: #493ab1 !important;
  font-size: 14px;
}

.notification_text {
  color: #888;
  font-size: 14px;
}

.alert_text {
  color: #0052cc;
  fill: #0052cc !important;
  font-size: 14px;
}

.icon {
  font-size: 14px !important;
}

@media screen and (max-width: 380px) {
  .notification_text {
    font-size: 10px;
  }

  .alert_text {
    font-size: 10px;
  }
}
