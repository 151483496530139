.title {
  font-weight: 700;
  font-size: 18px;
}

.statsContainer {
  height: 242px;
  align-items: center;
  justify-content: space-between;
}

.nameText {
  color: rgb(73, 58, 177);
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 767px) {
  .statsContainer {
    height: fit-content;
  }

  .AffliateManagementStatsContainer {
    padding: 16px;
    box-shadow: 1px 2px 4px 0 rgba(185, 178, 230, 0.2);
    border: 0.5px solid #e7e6e4;
  }

  .statCard {
    height: 97px;
  }
}
