.label {
  font-weight: 500;
  font-size: 14px;
}

.dropdown {
  padding: 10.5px 14px !important;
  height: 40px !important;
}

.switch_text {
  font-weight: 400;
  font-size: 16px;
}

.disclaimer {
  background-color: #eeecf9;
  border: 1.5px solid #493ab1;
  border-radius: 5px;
}

.disclaimer_text {
  color: #493ab1;
  font-size: 16px;
  font-weight: 400;
}

.disclaimer_icon {
  color: #493ab1 !important;
  font-size: 24px !important;
}
