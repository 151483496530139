.icon {
  width: 20px;
  height: 20px;
}

.settingsIcon {
  fill: #493ab1 !important;
}

.settingsButton:hover .settingsIcon {
  fill: white !important;
}

.title {
  font-weight: 700;
  font-size: 18px;
}

.statsContainer {
  height: 157px;
}
