.dates {
  font-size: 16px;
  font-weight: 500;
}

.pe_none {
  pointer-events: none;
}

div:has(.datePickerCalendar) {
  z-index: 11;
}

.statsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-shadow: 1px 2px 4px 0 rgba(185, 178, 230, 0.2);
  border: 0.5px solid #e7e6e4;
  border-radius: 16px;
  width: 100%;
  background-color: #fff;
}

.statTitle {
  word-break: normal;
}

.select {
  height: 40px !important;
  width: 153px !important;
  background-color: white;
  border: 1.5px solid #cfcdc9;
  border-radius: 5px;
}

.datePicker {
  height: 40px !important;
  background-color: white;
}

.credited_commission {
  background-color: rgba(229, 255, 247, 1) !important;
}

.credited_commission_text {
  color: rgba(0, 153, 102, 1) !important;
  fill: rgba(0, 153, 102, 1) !important;
}

.total_pending_commission {
  background-color: rgba(229, 240, 255, 1) !important;
}

.total_pending_commission_text {
  color: rgba(0, 82, 204, 1) !important;
  fill: rgba(0, 82, 204, 1) !important;
}

.icon {
  width: 32px !important;
  height: 32px !important;
}

.selectContainer {
  display: flex;
  align-items: center;
}

.statsCardContainer {
  margin-left: -16px !important;
  margin-right: -16px !important;
}

.statCard {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

@media screen and (max-width: 767px) {
  .statsContainer {
    box-shadow: none;
    border: none;
  }

  .selectContainer {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: start;
    margin-top: 10px;
  }

  .select {
    height: 32px !important;
  }

  .statsCardContainer {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }

  .statCard {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .statsNumberClass {
    font-size: 16px !important;
    font-weight: 700;
  }

  .statsTitleClass {
    font-size: 11px !important;
    font-weight: 500;
  }

  .datePickerContainer {
    flex: 1;
  }
}
