.wrapper {
  align-items: center;
  box-shadow: 1px 2px 4px 0 rgba(185, 178, 230, 0.2);
  border-radius: 16px;
  border: 0.5px solid #e7e6e4;
  background-color: #f8f7fd;
  height: 100%;
}
.commentAvatar {
  width: 100px !important;
  height: 100px !important;
  font-size: 37px !important;
  font-weight: 700 !important;
  margin-bottom: 8px !important;
}
.afflitateTitle {
  font-size: 12px;
  font-weight: 400;
  color: rgba(39, 37, 34, 0.5);
}
.afflitateDate {
  font-size: 14px;
  font-weight: 500;
  color: rgba(39, 37, 34, 0.5);
}
.name {
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
}
.contentWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.heading {
  display: flex;
  align-items: center;
}
.detailsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detailsWrapper:first-child {
  margin-left: 0px;
}
.secondaryButton {
  width: 100%;
}
.label {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16.7px !important;
  text-align: center;
}
.buttonWrapperClassName {
  width: 50%;
}
.amount {
  font-weight: 700;
  line-height: 19.54px !important;
  margin-bottom: 4px;
}
.dash {
  background-color: grey;
  width: 1px;
  height: 28px;
}
.secondWrapper {
  padding-left: 47px;
  padding-right: 47px;
}
.imgWrapper {
  margin-right: 6px;
}
.iconWrapper {
  border: 1px solid #493ab1;
  border-radius: 3.25px;
  padding: 8px;
  display: flex;
}
.iconStyles {
  width: 16px !important;
  height: 16px !important;
  fill: #493ab1 !important;
}

.commissionDetails {
  justify-content: space-between;
}

@media screen and (max-width: 780px) {
  .commentAvatar {
    width: 64px !important;
    height: 64px !important;
    font-size: 24px !important;
  }
  .name {
    font-size: 18px;
    line-height: 22px;
  }
  .contentWrap {
    gap: 13px;
  }
  .commissionDetails {
    font-size: 12px;
  }
  .afflitateTitle {
    font-size: 10px;
  }
  .afflitateDate {
    font-size: 12px;
  }
  .buttonWrapperClassName {
    width: 75%;
    font-size: 14px;
  }
  .dash {
    height: 20px;
  }
  .secondWrapper {
    padding: 0 18px;
  }
  .secondaryButton {
    height: 33px !important;
    font-size: 14px !important;
  }

  .PayoutActionsContainer {
    flex: 1;
  }
  .amount {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .label {
    font-size: 10px !important;
  }
}
