.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalWrapper {
  padding: 40px 25px;
  width: 381px !important;
}
.text {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 3px;
}
.verifyImg {
  margin-bottom: 15px;
}
.buttonWrapperClassName {
  margin-top: 24px;
  width: 100%;
}
.secondaryButton {
  width: 100%;
  font-weight: 500;
}
@media screen and (max-width: 780px) {
  .container {
    padding: 10px;
  }
  .verifyImg {
    margin-top: 30px;
  }
}
