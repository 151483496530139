.icon_wrapper_mb {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: max-content;
  align-items: center;
}

.stats_icon_mb {
  color: var(--icon-color);
  width: 20px !important;
  height: 20px !important;
}

.info_icon_wrapper_mb {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toolTipIcon {
  font-size: 16px;
  line-height: 22px;
  width: 16px !important;
  height: 16px !important;
}

.statsNumber {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: var(--stats-number-color);
  word-break: break-all;
}

.stats_title_mb {
  font-weight: 500 !important;
  font-size: 14px !important;
}
.card_wrapper_mb {
  min-height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  box-shadow: 0px 1px 1px 0px rgba(73, 58, 177, 0.08) !important;
}

@media screen and (max-width: 780px) {
  .icon_wrapper_mb {
    margin-bottom: 13px !important;
  }
  .stats_title_mb {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    word-break: break-word;
  }
  .card_wrapper_mb {
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0px 1px 1px rgba(73, 58, 177, 0.08);
    box-shadow: 0px 1px 1px 0px rgba(73, 58, 177, 0.08) !important;
  }
}
