.container {
  padding: 16px 28px 28px 16px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.name_field {
  color: #493ab1;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline !important;
  cursor: pointer !important;
}
@media screen and (max-width: 780px) {
  .container {
    padding: 0px;
  }
}
