.container {
  padding: 1rem;
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.statsWrapper {
  display: flex;
  gap: 20px;
}
.statsContainer {
  width: 70%;
}
.btn {
  height: auto !important;
  padding: 11px 20.5px !important;
  border-radius: 5px !important;
}
.datePickerWrapper {
  height: 35px !important;
}
.stats {
  gap: 13px;
  border: 0.5px solid #e7e6e4;
  background-color: white;
  border-radius: 16px;
  box-shadow: 1px 2px 4px 0 rgba(185, 178, 230, 0.2);
  padding: 12px 16px 12px 16px;
}
.icon_wrapper {
  margin-bottom: 0px;
}
.title_wrapper {
  margin-top: 0px !important;
}
.deleteButton {
  height: 33px !important;
  font-size: 14px !important;
  min-width: 94px !important;
  padding: 8px 6.5px !important;
}
.btnDiscount {
  margin-bottom: 16px;
}
.tableActionButtonWrapper {
  display: flex;
  justify-content: end;
}

@media screen and (max-width: 780px) {
  .statsWrapper {
    flex-direction: column;
    padding: 16px 16px 0 16px;
  }
  .container {
    padding: 0px;
  }
  .statsContainer {
    width: 100%;
  }
}
