.wrapper {
  border: 0.5px rgba(231, 230, 228, 1) solid;
  box-shadow: 1px 2px 4px 0 rgba(185, 178, 230, 0.2);
  border-radius: 16px;
  background-color: white;
}

.contentWrap {
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 16px 20px 0;
}
.imgWrapper {
  border-radius: 16px;
}

.imgWrap {
  position: relative;
  box-sizing: border-box;
}

.contentTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #272522;
  margin-bottom: 16px;
}

.contentDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: rgba(109, 103, 95, 0.7);
}
.copyIcon {
  display: flex;
  margin-top: 20px;
  gap: 10px;
}
.iconWrapper {
  padding: 11.5px 12px;
  background-color: #493ab1;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 48px !important;
  height: 47px !important;
  justify-content: center;
}
.iconStyles {
  color: white;
  width: 17px;
  height: 20px;
  cursor: pointer;
}

.inputContainer {
  flex: 1;
}

.inputWrapper {
  font-size: 16px;
  width: 100%;
}
.disabled {
  color: black !important;
  background-color: rgba(246, 246, 249, 1) !important;
  cursor: not-allowed;
  padding: 14px 16px !important;
  line-height: 19.09px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

@media (max-width: 780px) {
  .contentDescription {
    font-size: 12px;
    line-height: 14px;
  }
  .imgWrapper {
    height: 143px;
  }
  .contentTitle {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .contentWrap {
    padding-right: 10px;
  }
  .wrapper {
    background-color: #f8f7fd;
    margin-bottom: 0px !important;
  }
  .inputWrapper {
    height: 30px;
    border: #cfcdc9;
    font-size: 12px;
    background-color: #f6f6f9;
  }
  .iconStyles {
    width: 10px !important;
    height: 11px !important;
  }
  .disabled {
    padding: 4.5px 14px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .iconWrapper {
    padding: 8px 8.8px;
    width: 28px !important;
    height: 27px !important;
  }
}
