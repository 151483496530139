.mb {
  margin-bottom: 10px;
}
.halfWidth {
  width: 50%;
}
.contentWrap {
  padding: 20.5px 0 20.5px 0;
}
.commissionWrapper {
  display: flex;
  margin-bottom: 10px;
  gap: 20px;
}
.commissionInput {
  width: 50%;
  margin-bottom: 10px;
}
.input {
  display: flex;
  gap: 10px;
}
.fullWidth {
  width: 100%;
}
.disabled {
  background-color: rgba(246, 246, 249, 1) !important;
  cursor: not-allowed;
}
@media screen and (max-width: 780px) {
  .commissionWrapper {
    flex-direction: column;
    gap: 10px;
  }
  .commissionInput {
    width: 100%;
    margin-bottom: 10px;
  }
}
