.container {
  padding: 28px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.name_field {
  color: #493ab1;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline !important;
  cursor: pointer !important;
}
.name {
  text-decoration: underline !important;
}
.stats {
  background-color: #f8f7fd;
}
@media screen and (max-width: 780px) {
  .container {
    background-color: rgba(252, 251, 254, 1);
    padding: 0px;
  }

  .name_field {
    font-size: "14px";
    line-height: "20px";
  }
}
