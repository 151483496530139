.dates {
  font-size: 16px;
  font-weight: 500;
}

.statsContainer {
  display: flex;
  flex-direction: column;
  box-shadow: 1px 2px 4px 0 rgba(185, 178, 230, 0.2);
  border: 0.5px solid #e7e6e4;
  border-radius: 16px;
  padding: 16px;
  width: 100%;
  background-color: #f8f7fd;
  justify-content: center;
}

.bookingIcon {
  fill: rgba(0, 82, 204, 1) !important;
}

.viewIcon {
  fill: #493ab1 !important;
}
.revenueIcon {
  fill: rgba(0, 153, 102, 1) !important;
}
.datePickerContainer {
  flex: 1;
}

.statCard {
  height: 138px;
}

.statTitle {
  word-break: normal;
}

.select {
  height: 40px !important;
  width: 153px !important;
  background-color: white;
  border: 1.5px solid #cfcdc9;
  border-radius: 5px;
}

.datePicker {
  height: 40px !important;
  background-color: white;
}

.total_visitors {
  background-color: #e7e4f6 !important;
  color: #493ab1 !important;
}
.total_bookings {
  background-color: #dbeaff !important;
  color: #0052cc !important;
}
.total_revenue {
  background-color: #dbfff4 !important;
  color: #009966 !important;
}

.selectContainer {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .statCard {
    height: 122px;
  }

  .cardsContainer {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }

  .statCardContainer {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .select {
    height: 32px !important;
  }

  .selectContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: start;
  }

  .statsContainer {
    background-color: #fff;
    border: none;
    box-shadow: none;
    padding: 0;
  }
}
