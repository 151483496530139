.icon {
  width: 20px;
  height: 20px;
}

.settingsIcon {
  fill: #493ab1 !important;
}

.settingsButton:hover .settingsIcon {
  fill: white !important;
}

.title {
  font-weight: 700;
  font-size: 18px;
}

.statsContainer {
  height: 242px;
}

@media screen and (max-width: 767px) {
  .settingsIcon {
    width: 22px !important;
    height: 22px !important;
  }

  .statsContainer {
    height: fit-content;
  }
}
