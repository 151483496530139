.secondaryButton {
  width: 100%;
}
.imgWrapper {
  margin-right: 6px;
}
.buttonWrapperClassName {
  width: 50%;
}
@media screen and (max-width: 780px) {
  .secondaryButton {
    height: 33px !important;
    font-size: 14px !important;
  }
  .buttonWrapperClassName {
    width: 75%;
    font-size: 14px;
  }
}
