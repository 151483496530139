.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.name_Field {
  cursor: default !important;
}
.name_Field:hover {
  text-decoration: none !important;
}
.statsWrapper {
  display: flex;
  gap: 20px;
  margin-bottom: 24px !important;
}
.statsContainer {
  width: 70%;
}
.datePickerWrapper {
  height: 35px !important;
}
.stats {
  gap: 16px !important;
  border: 0.5px solid #e7e6e4;
  background-color: white;
  border-radius: 16px;
  box-shadow: 1px 2px 4px 0 rgba(185, 178, 230, 0.2);
  padding: 16px;
  justify-content: space-between;
}
.icon_wrapper {
  margin-bottom: 15px !important;
}
.title_wrapper {
  margin-top: 0px !important;
}
.description {
  margin-top: 10px !important;
}
.primaryBtn {
  background-color: #00b77a !important;
  color: #ffffff !important;
  font-size: 16px;
  font-weight: 500;
}
.sendRemainder {
  background-color: #ffaa00 !important;
}
.invoice {
  text-decoration: underline;
}
.cachedIcon {
  width: 16px !important;
  height: 16px !important;
  color: #493ab1;
}
.retry {
  display: flex;
  align-items: center;
  gap: 5px;
}
.actionsWrapper {
  padding-right: 12px !important;
}
.secondaryButton {
  color: #493ab1 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  background-color: #dcd9f2 !important;
  height: 0px !important;
}
.icons {
  width: 16px !important;
  height: 16px !important;
  margin-right: 1px !important;
}
@media screen and (max-width: 780px) {
  .statsWrapper {
    flex-direction: column;
    padding: 16px 16px 0 16px;
  }
  .statsContainer {
    width: 100%;
  }
  .headerWrapper {
    margin-bottom: 0px !important;
  }
  .affliateId {
    font-size: 16px !important;
  }
}
