.container {
  padding: 1rem;
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.retry {
  display: flex;
  align-items: center;
  gap: 5px;
}
.cachedIcon {
  width: 16px !important;
  height: 16px !important;
  color: #493ab1;
}
.name_field {
  color: #493ab1;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline !important;
  cursor: pointer !important;
}
@media screen and (max-width: 780px) {
  .container {
    padding: 0px;
  }
}
